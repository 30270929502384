import logger from '../logger';
import { assetManagerUtils } from './asset-manager-utils';
import { utils } from '../utils';

const getAsset = async (assetReferenceType, isModelCompare, createAsset, additionalCreationData) => {
  const assets = assetManagerUtils.getAssetSdk();
  if (!assets) {
    return null;
  }
  const orderId = utils.getOrderId(isModelCompare);
  const rxGuid = utils.getRxGuid(isModelCompare);

  const requestModel = {
    assetReferenceType: assetReferenceType,
    orderId: orderId || 0,
    rxId: rxGuid,
  };

  const accessToken = utils.getAccessToken();
  const asset = createAsset
    ? await assets.getAndCreateAsset(requestModel, accessToken, additionalCreationData)
    : await assets.getAsset(requestModel, accessToken);

  return { arrayBuffer: () => asset };
};

const getAssetWithPolling = async (assetReferenceType, isModelCompare) => {
  let asset;

  try {
    asset = await getAsset(assetReferenceType, isModelCompare, true);
    if (asset) {
      return asset;
    }
  } catch (error) {
    if (error && error.statusCode !== 404) {
      throw error;
    }

    logger
      .error(`fetchItrFileWithPolling: error: ${error}`)
      .data({ module: 'itr-fetcher' })
      .to(['host'])
      .end();
    asset = await assetManagerUtils.pollForAssetCreation(() => getAsset(assetReferenceType, isModelCompare, false));
  }

  if (asset) {
    return asset;
  }

  logger
    .error(`fetchItrFileWithPolling: error: max attempts reached`)
    .data({ module: 'itr-fetcher' })
    .to(['host'])
    .end();

  throw new Error('fetchItrFileWithPolling: Max attempts reached');
};

export const assetManagerRequests = {
  getAsset,
  getAssetWithPolling,
};
