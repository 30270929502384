import * as THREE from 'three';
import { get, isEmpty, keys, cloneDeep } from 'lodash';
import {
  TeethNumbersConstants,
  PREP_PREFIX,
  ObjectSuffix,
  ObjectsKeys,
  modelTypes,
  JAWS_ENUM,
} from './../constants/model.constants';
import { cacheManager, cacheKeys } from '../cache-manager';
import { IOC, MODEL_COMPARE, NIRI } from '../constants/tools.constants';

const { ORIGIN, COMPARE } = modelTypes;

export const isOCCExistsInGeometry = (geometry) => {
  return checkIfAttributeExistInGeometry(geometry, 'uv');
};

export const isOCCExistsInModel = (objects) => {
  const isOCCExists = Object.values(objects).reduce((acc, geometry) => {
    acc = acc || isOCCExistsInGeometry(geometry);
    return acc;
  }, false);
  return isOCCExists;
};

export const isColorExistsInGeometry = (geometry) => {
  return Boolean(geometry && geometry.attributes && geometry.attributes.color && geometry.attributes.color.count > 0);
};

export const isTextureMappingExistInModel = (objects) => {
  const isTMExists = Object.values(objects).reduce((acc, geometry) => {
    acc = acc || isTextureMappingExistInGeometry(geometry);
    return acc;
  }, false);

  return isTMExists;
};

export const isTextureMappingExistInGeometry = (geometry) => {
  return Boolean(geometry.attributes && geometry.attributes.uvTexture && geometry.attributes.uvTexture.count > 0);
};

export const isTextureMappingExistInTextures = (textures) => {
  const isTextureMappingExist = Object.values(textures).reduce((acc, texture) => {
    acc = acc || texture.name.includes('texture_mapping');
    return acc;
  }, false);
  return isTextureMappingExist;
};

export const checkIfAttributeExistInGeometry = (geometry, attributeName) => {
  const uvArr = get(geometry, `attributes.${attributeName}`) || null;

  if (!uvArr) {
    return false;
  }

  // using a for loop and break if we get 2 unique values, no need
  // to look at the rest of the array
  const uniqueValues = [];
  for (let i = 0; i < uvArr.array.length; i++) {
    if (uvArr.array[i] !== 0 && !uniqueValues.includes(uvArr.array[i])) {
      uniqueValues.push(uvArr.array[i]);
    }

    if (uniqueValues.length === 2) {
      break;
    }
  }

  return uniqueValues.length > 1;
};

export const isObjectExists = (objects, objName) => objName && objects && objName in objects;

export const isObjectHasGeometry = (objects, objName) => {
  const geometry = get(objects, objName);
  return geometry && !isEmptyGeometry(geometry);
};

export const isObjectEmpty = (objects, objName) => objName && objects && isEmpty(objects[objName]);

export const isUpperJawEnable = (objects) => {
  if (!objects) return false;

  return (
    isUpperDefaultJawExists(objects) ||
    isAnyPrepExistsInUpperJaw(objects) ||
    isUpperPretreatmentJawExists(objects) ||
    isUpperDentureCopyScanJawExists(objects) ||
    isUpperEmergenceProfileJawExists(objects)
  );
};

export const isLowerJawEnable = (objects) => {
  if (!objects) return false;

  return (
    isLowerDefaultJawExists(objects) ||
    isAnyPrepExistsInLowerJaw(objects) ||
    isLowerPretreatmentJawExists(objects) ||
    isLowerDentureCopyScanJawExists(objects) ||
    isLowerEmergenceProfileJawExists(objects)
  );
};

export const isUpperDefaultJawExists = (objects) =>
  isObjectExists(objects, ObjectsKeys.UPPER_JAW) && !isObjectEmpty(objects, ObjectsKeys.UPPER_JAW);

export const isLowerDefaultJawExists = (objects) =>
  isObjectExists(objects, ObjectsKeys.LOWER_JAW) && !isObjectEmpty(objects, ObjectsKeys.LOWER_JAW);

export const isUpperPretreatmentJawExists = (objects) =>
  isObjectExists(objects, ObjectsKeys.UPPER_PRETREATMENT_JAW) &&
  !isObjectEmpty(objects, ObjectsKeys.UPPER_PRETREATMENT_JAW);

export const isLowerPretreatmentJawExists = (objects) =>
  isObjectExists(objects, ObjectsKeys.LOWER_PRETREATMENT_JAW) &&
  !isObjectEmpty(objects, ObjectsKeys.LOWER_PRETREATMENT_JAW);

export const isUpperDentureCopyScanJawExists = (objects) =>
  isObjectExists(objects, ObjectsKeys.UPPER_DENTURE_COPY_SCAN_JAW) &&
  !isObjectEmpty(objects, ObjectsKeys.UPPER_DENTURE_COPY_SCAN_JAW);

export const isLowerDentureCopyScanJawExists = (objects) =>
  isObjectExists(objects, ObjectsKeys.LOWER_DENTURE_COPY_SCAN_JAW) &&
  !isObjectEmpty(objects, ObjectsKeys.LOWER_DENTURE_COPY_SCAN_JAW);

export const isUpperEmergenceProfileJawExists = (objects) =>
  isObjectExists(objects, ObjectsKeys.UPPER_EMERGENCE_PROFILE_JAW) &&
  !isObjectEmpty(objects, ObjectsKeys.UPPER_EMERGENCE_PROFILE_JAW);

export const isLowerEmergenceProfileJawExists = (objects) =>
  isObjectExists(objects, ObjectsKeys.LOWER_EMERGENCE_PROFILE_JAW) &&
  !isObjectEmpty(objects, ObjectsKeys.LOWER_EMERGENCE_PROFILE_JAW);

export const isAnyPretreatmentJawExists = (objects) =>
  isUpperPretreatmentJawExists(objects) || isLowerPretreatmentJawExists(objects);

export const isAnyDentureCopyScanJawExists = (objects) =>
  isUpperDentureCopyScanJawExists(objects) || isLowerDentureCopyScanJawExists(objects);

export const isAnyEmergenceProfileJawExists = (objects) =>
  isUpperEmergenceProfileJawExists(objects) || isUpperEmergenceProfileJawExists(objects);

const isAnyPrepExistsInUpperJaw = (objects) => isAnyPrepExistsInJaw(objects, true);
const isAnyPrepExistsInLowerJaw = (objects) => isAnyPrepExistsInJaw(objects, false);

export const isAnyPrepExistsInJaw = (objects, isUpper) => {
  var { fromAdaId, toAdaId } = isUpper
    ? {
        fromAdaId: TeethNumbersConstants.UPPER_JAW_FIRST_TEETH_NUMBER,
        toAdaId: TeethNumbersConstants.UPPER_JAW_LAST_TEETH_NUMBER,
      }
    : {
        fromAdaId: TeethNumbersConstants.LOWER_JAW_FIRST_TEETH_NUMBER,
        toAdaId: TeethNumbersConstants.LOWER_JAW_LAST_TEETH_NUMBER,
      };

  for (let adaId = fromAdaId; adaId <= toAdaId; adaId++) {
    if (isObjectHasGeometry(objects, ObjectsKeys.Preps[adaId].Prep)) {
      return true;
    }
  }
  return false;
};

export const isEmptyGeometry = (geometry) => {
  if (!get(geometry, 'computeBoundingBox')) return true;
  geometry.computeBoundingBox();
  const boundingBox = geometry.boundingBox;
  if (boundingBox === null) return true;
  const zeroVector = new THREE.Vector3();
  return boundingBox.min.equals(zeroVector) && boundingBox.max.equals(zeroVector);
};

export const parseMarginLineGeometry = (str) => {
  if (str === undefined || typeof str !== 'string') return null;
  const lines = str.split('\n');
  const numOfLines = parseInt(lines[0], 10);
  if (lines.length < numOfLines) return null;

  const pts = getMarginLinePoints(lines, numOfLines);
  const rectShape = getRectShape();
  const curvesPath = new THREE.CatmullRomCurve3(pts);

  const extrudeSettings = {
    steps: numOfLines,
    bevelEnabled: false,
    extrudePath: curvesPath,
  };

  const geometry = new THREE.ExtrudeGeometry(rectShape, extrudeSettings);
  const lineColor = new THREE.Color(0xff0000);

  return new THREE.Mesh(geometry, new THREE.LineBasicMaterial({ color: lineColor }));
};

export const getMarginLinePoints = (lines, numOfLines) => {
  if (!lines || !numOfLines) return [];
  const pts = [];
  for (let i = 1; i <= numOfLines; i++) {
    const pointsArray = lines[i].split(' ');
    pts.push(new THREE.Vector3(Number(pointsArray[0]), Number(pointsArray[1]), Number(pointsArray[2])));
  }
  return pts;
};

export const getRectShape = (side = 0.025) => {
  if (typeof side !== 'number') return null;
  const rectShape = new THREE.Shape();
  rectShape.moveTo(0, 0);
  rectShape.lineTo(0, side);
  rectShape.lineTo(side, side);
  rectShape.lineTo(side, -1 * side);
  rectShape.lineTo(-1 * side, -1 * side);
  rectShape.lineTo(-1 * side, side);
  rectShape.lineTo(0, side);
  rectShape.lineTo(0, 0);
  return rectShape;
};

export const getPrepsFromModels = (objects) => {
  const prepsInModel = {};
  if (typeof objects !== 'object') return prepsInModel;
  Object.keys(objects).forEach((key) => {
    if (!key.includes(PREP_PREFIX)) return;
    let prepId = parseInt(key.replace(PREP_PREFIX, ''));
    if (prepsInModel[PREP_PREFIX + prepId]) {
      return;
    }
    prepsInModel[PREP_PREFIX + prepId] = {
      id: prepId,
      checked: false,
      disabled: isEmpty(objects[PREP_PREFIX + prepId]),
    };
  });
  return prepsInModel;
};

export const isAnyPrepsExists = (objects) => isAnyPrepExistsInLowerJaw(objects) || isAnyPrepExistsInUpperJaw(objects);

export const getAvailableSuffixForPrep = (prepPrefix) => {
  if (!prepPrefix) return [];
  return [
    `${prepPrefix}${ObjectSuffix.ADJACENT}`,
    `${prepPrefix}${ObjectSuffix.DITCH}${ObjectSuffix.INNER}`,
    `${prepPrefix}${ObjectSuffix.DITCH}${ObjectSuffix.OUTER}`,
    `${prepPrefix}${ObjectSuffix.MARGIN_LINE}`,
    prepPrefix,
  ];
};

export const getObjectsKeysBySuffix = (objects, objectType) => {
  if (!objects || !objectType) {
    return [];
  }
  return keys(objects).reduce((acc, modelObject) => {
    modelObject.includes(objectType) && acc.push(modelObject);
    return acc;
  }, []);
};

export const getCameraPosByCaseType = (caseType, side) => {
  const positions = {
    ortho: {
      u: { position: [0, 0, 200], up: [0, -1, 0] },
      n: { position: [0, 0, -200], up: [0, 1, 0] },
      front: { position: [0, 200, 0], up: [0, 0, 1] },
      left: { position: [-100, 100, 0], up: [0, 0, 1] },
      right: { position: [100, 100, 0], up: [0, 0, 1] },
    },
    resto: {
      u: { position: [0, 0, 200], up: [0, 1, 0] },
      n: { position: [0, 50, -200], up: [0, -1, 0] },
      front: { position: [0, -200, 0], up: [0, 0, 1] },
      left: { position: [100, 0, 0], up: [0, 0, 1] },
      right: { position: [-100, 0, 0], up: [0, 0, 1] },
    },
  };
  return get(positions, `${caseType}.${side}`);
};

export const unCheckAllPreps = (preps) => {
  const newPrepsState = cloneDeep(preps);

  keys(newPrepsState).forEach((key) => {
    newPrepsState[key].checked = false;
  });

  return newPrepsState;
};

export const getCurrentJawNameByCamera = (camera, modelType) => {
  const threeObjects = cacheManager.get(cacheKeys.THREE_OBJECTS) || {};
  const cameraUUID = camera.uuid;
  const jawName = Object.entries(threeObjects[modelType]).find(
    ([key, jaw]) => jaw.camera && jaw.camera.uuid === cameraUUID
  )[0];
  return [jawName];
};

// region
// models sync engine (TODO move to separate file modelSync.engine.js)
export const setModelPosition = (positions, type, shouldSetZoom = true) => {
  const compareCameraObjects = getCamerasForCompareSync();
  const cameraObjects = Object.values(compareCameraObjects).find((camObjects) => camObjects.modelType === type);
  if (cameraObjects && positions) {
    const { camera } = cameraObjects;
    const { position, rotation, up, zoom } = positions;

    camera.position.copy(position);
    camera.rotation.copy(rotation);
    camera.up.set(up.x, up.y, up.z);
    if (shouldSetZoom && zoom) {
      camera.zoom = zoom;
    }
  }
};

export const getCamerasForCompareSync = () => {
  const cameras = {};
  const threeObjects = cacheManager.get(cacheKeys.THREE_OBJECTS);
  const visibilityObject = cacheManager.get(cacheKeys.VISIBILITY_OBJECT) || [];
  const jawName = visibilityObject.length === 1 ? visibilityObject[0] : JAWS_ENUM.lower_jaw;

  threeObjects &&
    [ORIGIN, COMPARE].forEach((type) => {
      const { camera, gl, scene, controls } = threeObjects[type][jawName] || {};
      if (camera && scene && controls) {
        cameras[camera.uuid] = { camera, gl, scene, controls, modelType: type };
      }
    });
  return cameras;
};

export const syncModelCompareCameras = ({
  currentActiveCamera,
  isModelCompareInDifferentMode,
  isWheelZoom = false,
  shouldSetZoom = true,
  isResto,
}) => {
  const cameras = getCamerasForCompareSync();

  if (!currentActiveCamera || !cameras[currentActiveCamera.uuid] || Object.keys(cameras).length !== 2) return;

  // current camera
  const { uuid, parent, up: currentUp } = currentActiveCamera;
  const currentActiveControls = cameras[uuid].controls || parent.__objects[0];
  const currentCOO = currentActiveControls.centerOfOrbit;

  // other camera
  const otherCameraObject = Object.values(cameras).find(({ camera }) => camera.uuid !== uuid);
  const { camera: otherCamera, scene: otherScene, controls: otherControls } = otherCameraObject;
  const otherCOO = otherControls ? otherControls.centerOfOrbit.clone() : otherScene.__objects[0].centerOfOrbit;

  if (isWheelZoom) {
    otherCamera.zoom = currentActiveCamera.zoom;
  } else {
    const currentPosition = currentActiveCamera.position;
    const currentRotation = currentActiveCamera.rotation;
    const posReplica = currentPosition.clone();
    const rotReplica = currentRotation.clone();
    const { position, rotation, up } = otherCamera;

    if (isModelCompareInDifferentMode) {
      const currentCameraModeType = cameras[currentActiveCamera.uuid].modelType;
      const isCurrentCameraFromMainModel = currentCameraModeType === ORIGIN;
      const isMainModelResto = isResto(ORIGIN);

      otherCOO.set(otherCOO.x, otherCOO.y, -1 * otherCOO.z);
      const cooDiff = currentCOO.clone();
      cooDiff.sub(otherCOO);
      posReplica.set(-1 * currentPosition.x, -1 * currentPosition.y, posReplica.z);

      if (isMainModelResto) {
        isCurrentCameraFromMainModel ? posReplica.sub(cooDiff) : posReplica.add(cooDiff);
      } else {
        isCurrentCameraFromMainModel ? posReplica.add(cooDiff) : posReplica.sub(cooDiff);
      }

      let z = currentRotation.z + Math.PI;
      if (z > Math.PI) {
        z = z - 2 * Math.PI;
      }

      rotReplica.set(-1 * currentRotation.x, -1 * currentRotation.y, z);
      up.set(-1 * currentUp.x, -1 * currentUp.y, currentUp.z);
    }

    position.copy(posReplica);
    rotation.copy(rotReplica);
    if (shouldSetZoom) otherCamera.zoom = currentActiveCamera.zoom;
  }

  Object.values(cameras).forEach((cameraObjects) => {
    const { scene, gl, camera } = cameraObjects;
    camera.updateProjectionMatrix();
    gl.render(scene, camera);
  });
};

export const getModelMovement = ({ isEnlarged, imageFrameDimentions, isResto }) => {
  const convertPixelsToMilimeters = (pixels) => {
    const PPI = window.devicePixelRatio * 96;
    const inchInMilimeters = 25.4;
    return (pixels / PPI) * inchInMilimeters;
  };

  const { width, enlargeWidth } = imageFrameDimentions || {};
  const multiplyer = isResto ? 1 : -1;
  const frameWidth = isEnlarged ? enlargeWidth : width;
  const screenWidthInMilimeters = convertPixelsToMilimeters(window.innerWidth - frameWidth);
  const moveLeftPercent = frameWidth / window.innerWidth;
  const pixelsToMove = screenWidthInMilimeters * moveLeftPercent;
  const moveXmm = multiplyer * convertPixelsToMilimeters(pixelsToMove);

  return moveXmm;
};

export const setModelPositionInCompareMode = ({ sdk, cameraPosition, position, up, currentActiveJaw }) => {
  const { isModelCompareInDifferentMode, isModelCompared } = sdk.getPluginParameters(MODEL_COMPARE.id);

  const correctCameraProps = cameraPosition[0].filter((camPos) =>
    camPos.ignoreModels ? camPos.ignoreModels[0] !== currentActiveJaw : camPos
  );
  correctCameraProps.forEach(({ cameraProps }, index) => {
    if (isModelCompared && isModelCompareInDifferentMode && index === 0) {
      const cloneUp = up.clone();
      const clonePosition = position.clone();

      cloneUp.set(-1 * cloneUp.x, -1 * cloneUp.y, cloneUp.z);
      clonePosition.set(-1 * clonePosition.x, -1 * clonePosition.y, clonePosition.z);

      cameraProps.position = clonePosition.toArray();
      cameraProps.up = cloneUp.toArray();
    } else {
      cameraProps.position = position.toArray();
      cameraProps.up = up.toArray();
    }
  });

  return cameraPosition;
};

export const updateCameraPositionForCompare = (sdk, cameraPosition, reset) => {
  let toolsState = null;
  const { position: initialPosition, up: initialUp } = cameraPosition[0][0].cameraProps;

  [IOC.id, NIRI.id].forEach((pluginId) => {
    const { reviewToolsState } = sdk.getPluginParameters(pluginId) || {};
    if (reviewToolsState) toolsState = reviewToolsState;
  });

  if (!reset && toolsState) {
    const { activeJaw } = toolsState;
    const { position, up } = toolsState[activeJaw || JAWS_ENUM.lower_jaw];

    return setModelPositionInCompareMode({ sdk, cameraPosition, position, up, currentActiveJaw: activeJaw });
  } else {
    const initialPositionVec = new THREE.Vector3().fromArray(initialPosition);
    const initialUpVec = new THREE.Vector3().fromArray(initialUp);
    return setModelPositionInCompareMode({ sdk, cameraPosition, position: initialPositionVec, up: initialUpVec });
  }
};

export const checkRender = (modelType) => {
  let isRendered = false;
  const threeObjects = cacheManager.get(cacheKeys.THREE_OBJECTS);
  const visibilityObject = cacheManager.get(cacheKeys.VISIBILITY_OBJECT) || [];

  threeObjects &&
    visibilityObject.forEach((jaw) => {
      const objects = threeObjects[modelType] || threeObjects;
      const { canvas, gl } = objects[jaw] || objects;

      if (canvas) {
        const { width, height } = canvas;
        const buffer = new Uint8Array(width * height * 4);
        const glContext = gl.getContext();
        glContext.readPixels(0, 0, width, height, glContext.RGBA, glContext.UNSIGNED_BYTE, buffer);
        isRendered = buffer.some((b) => b !== 0);
      }
    });
  return isRendered;
};

// end region
