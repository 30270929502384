import { Assets } from '@itero/assets-bff-sdk';
import { utils } from '../utils';
import logger from '../logger';
import { delayUtils } from './delay-utils';

const pollForAssetCreation = async (getAssetFunc) => {
  const INITIAL_ATTEMPTS = 15;
  const INITIAL_INTERVAL = 3000;
  const FINAL_INTERVAL = 15000;
  const TIMEOUT_THRESHOLD = 360000;
  let isTimeoutReached = false;

  delayUtils.delayCallback(TIMEOUT_THRESHOLD, () => {
    isTimeoutReached = true;
  });

  for (let i = 0; i < INITIAL_ATTEMPTS; i++) {
    try {
      return await getAssetFunc();
    } catch (error) {
      logger
        .error(`pollForAssetCreation: error: ${error}`)
        .data({ module: 'itr-fetcher' })
        .to(['host'])
        .end();
      await delayUtils.delay(INITIAL_INTERVAL);
    }
  }

  while (!isTimeoutReached) {
    try {
      return await getAssetFunc();
    } catch (error) {
      logger
        .error(`pollForAssetCreation: error: ${error}`)
        .data({ module: 'itr-fetcher' })
        .to(['host'])
        .end();
      await delayUtils.delay(FINAL_INTERVAL);
    }
  }
};

const getAssetSdk = () => {
  if (!utils) {
    return null;
  }
  const assetManagerUrl = utils.getAssetManagerUrl();
  if (!assetManagerUrl) {
    return null;
  }

  return new Assets(assetManagerUrl);
};

export const assetManagerUtils = {
  getAssetSdk,
  pollForAssetCreation,
};
