import { Vector3, MathUtils } from 'three';
import { commonConstants } from './lumina/const_params';

const cache = {
  prevRotation: 0,
};
const photoObjKeys = new Set(['niri', 'color']);

export const getClosestPhotoObjectRTH = ({
  camera,
  jawName,
  intersect,
  imagesCenter,
  jawsPhotosMetadata,
  viewer360Align2DImages,
}) => {
  const currentActiveJaw = jawsPhotosMetadata && jawsPhotosMetadata[jawName];

  if (!intersect || !currentActiveJaw) {
    return null;
  }

  let rotation = 0;
  let minDistance = Infinity;

  const xCamAxis = new Vector3();
  const yCamAxis = new Vector3();
  const zCamAxis = new Vector3();
  camera.matrixWorld.extractBasis(xCamAxis, yCamAxis, zCamAxis);

  const objectElement = Object.keys(currentActiveJaw[0]).filter((key) => photoObjKeys.has(key));

  const selectedPhotoObject = currentActiveJaw.reduce((acc, photoObj) => {
    for (let keyIndex = 0; keyIndex < objectElement.length; keyIndex++) {
      const item = photoObj[objectElement[keyIndex]] || {};
      const worldToCam = item.worldToCamMatrix;

      const screenPoint = intersect.point.clone();
      screenPoint.applyMatrix4(worldToCam);
      screenPoint.set(screenPoint.x / screenPoint.z, screenPoint.y / screenPoint.z, 0);

      const rotationVector = new Vector3(...item.rawImageMatrix.slice(8, 11));
      const dotProduct = rotationVector.dot(zCamAxis);

      const distanceFromImageCenter = screenPoint.distanceTo(imagesCenter);
      const distance = 1 - dotProduct + distanceFromImageCenter / commonConstants.RATIO_PIXEL_TO_ANGLE;

      if (distance < minDistance) {
        minDistance = distance;
        acc = photoObj;
      }
    }
    return acc;
  }, {});

  const rowImageMatrix = selectedPhotoObject.color.rawImageMatrix;
  const dot = rowImageMatrix.slice(0, 3);
  const photosRightAxis = new Vector3(...dot);

  if (viewer360Align2DImages) {
    const dot_y = rowImageMatrix.slice(4, 7);
    const photos_y_axis = new Vector3(...dot_y);
    const rotationDirection = photos_y_axis.dot(xCamAxis);
    const rotationDegrees = MathUtils.radToDeg(Math.acos(photosRightAxis.dot(xCamAxis)));
    rotation = rotationDirection > 0 ? rotationDegrees : -rotationDegrees;
    selectedPhotoObject.shouldTransform =
      Math.abs(Math.abs(cache.prevRotation) - Math.abs(rotation)) < commonConstants.max_image_rotation_threshold;
  } else {
    rotation = photosRightAxis.dot(xCamAxis) < 0 ? 180 : 0;
    selectedPhotoObject.shouldTransform = false;
  }

  cache.prevRotation = rotation;
  selectedPhotoObject.rotation = rotation || 0;
  return selectedPhotoObject;
};
