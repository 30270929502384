import { template, endsWith } from 'lodash';
import fetchProgress from 'fetch-progress';
import { logToTimber, logToTimberBI, biMethods } from '../timberLogger';
import { map } from './apiMap';
import { ScannerEnvironmentURLs } from '../constants/environment.constants';
import { settingsManager } from '../settings-manager';
import * as configuration from '../constants/configurationValues.constants';
import { utils } from '../utils';
import logger from '../logger';
import request from './apiService';
import { appSettingsManager } from '../app-settings-manager';
import { cacheKeys, cacheManager } from '../cache-manager';

const logToGAAndTimber = ({
  url,
  selector,
  options = {},
  status,
  howRequestWasProcessed,
  errorMessage = null,
  module = 'api-service-helper',
  biResultOptions = {},
}) => {
  const fileType = options.fileType;
  const metaData = {
    url,
    orderId: settingsManager.getConfigValue(configuration.orderId),
    options,
    howRequestWasProcessed,
  };

  if (errorMessage) {
    metaData.errorMessage = errorMessage;
  }

  logger
    .info('Network')
    .to(['analytics', 'host'])
    .data({ fileType, ...metaData, module: module })
    .end();

  logToTimber({
    timberData: {
      action: `api call: ${url}`,
      module: module,
      type: 'object',
      actor: 'System',
      value: metaData,
    },
  });

  logToTimberBI(
    biMethods.apiReportBiLog({
      name: module,
      action: 'GET',
      url: url,
      parameters: '',
      caller: '',
      result: {
        options: biResultOptions,
        status: howRequestWasProcessed,
      },
    })
  );
};

/**
 * This method is used for debug mode when we run the viewer standalone
 * @param {String} selector - the key for api request from the apiConfig.js
 */
const handleStandaloneRequest = (selector) => {
  let key = selector || '';
  const { localhostMockSettings } = cacheManager.get(cacheKeys.APP_SETTINGS) || {};
  const mockResponseBody = localhostMockSettings[key] || {};

  return new Response(JSON.stringify(mockResponseBody), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const handleDownloadFileRequest = async (response) => {
  let contentLength = 0;
  const isStandaloneMode = utils.isStandaloneMode();

  if (isStandaloneMode) {
    // because in standalone mode we are not getting 'content-length' header from API we need to check if file has content.
    const clone = response.clone();
    const blob = await clone.blob();
    contentLength = parseFloat((blob.size / 1024 / 1024).toFixed(2), 10);
  }

  const modelSize = parseFloat(response.headers.get('content-length') || contentLength, 10) / 1024 / 1024;
  if (modelSize == null || isNaN(modelSize)) {
    throw new Error('model size error');
  }

  return modelSize;
};

const handleRequest = async (props) => {
  const { url, selector, type = 'api-call', options = {}, progressCB = () => {}, module, ...other } = props;
  let response = null;
  let timberLoggingProps = {};

  try {
    const isStandaloneMode = utils.isStandaloneMode();
    if (isStandaloneMode && selector && type !== 'download-file') {
      return handleStandaloneRequest(selector, timberLoggingProps);
    }

    logger.time(`request: ${url || selector}`);

    const response = await request({ ...props, ...other, options });
    const requestUrl = response.url;

    const progressInterceptor = fetchProgress({ onProgress: (progressData) => progressCB(progressData.percentage) });
    const result = await progressInterceptor(response);
    const timeToDownloadMs = logger.timeEnd(`request: ${requestUrl}`, { module: module });
    const isSuccess = result && result.status && result.status >= 200 && result.status < 300;
    const modelSize = type && type === 'download-file' && (await handleDownloadFileRequest(result));

    if (isSuccess) {
      timberLoggingProps = {
        url: requestUrl,
        selector,
        status: response.status,
        options: modelSize
          ? { fileSize: `${modelSize}MB`, timeToDownloadMs, ...options, ...other }
          : { ...options, timeToDownloadMs },
        howRequestWasProcessed: 'Succeeded',
        module,
        biResultOptions: modelSize ? { fileSize: `${modelSize}MB`, timeToDownloadMs: timeToDownloadMs || '' } : {},
      };
    } else {
      timberLoggingProps = {
        url: requestUrl,
        selector,
        status: response.status,
        options: { ...options, timeToDownloadMs },
        errorMessage: `failed to fetch - response status: ${response.status}`,
        howRequestWasProcessed: 'Failed',
        module,
      };
    }
    return result;
  } catch (err) {
    const stackTrace = { selector, url };
    const errorMessage = `error while fetching url: ${url || selector}. ${err}`;
    timberLoggingProps = {
      url: url,
      selector,
      status: response && response.status,
      options,
      howRequestWasProcessed: 'Failed',
      module,
      errorMessage: errorMessage,
    };
    return Promise.reject({ errorMessage, ...stackTrace });
  } finally {
    logToGAAndTimber(timberLoggingProps);
  }
};

const addHeaders = ({ headersObj = {}, headers = new Headers() }) => {
  Array.from(Object.entries(headersObj)).forEach(([key, value]) => {
    key && value && headers.set(key, value);
  });
  return headers;
};

const compileUrl = ({ baseUrl, url, pathParams, queryParams }) => {
  const compiledUrl = `${template(url)(pathParams || {})}${encodeQueryParams(queryParams)}`;
  return `${baseUrl}/${compiledUrl}`;
};

const encodeQueryParams = (queryParams) => {
  if (!queryParams) {
    return '';
  }
  const ret = [];

  for (const paramKey in queryParams) {
    if (paramKey) {
      const value = queryParams[paramKey];
      const paramValue = Array.isArray(value)
        ? value
            .map((obj) => {
              if (typeof obj === 'object') {
                const [key, value] = Object.entries(obj)[0];
                return `${key}=${encodeURIComponent(value)}`;
              }
              return obj;
            })
            .join('&')
        : encodeURIComponent(value);

      ret.push(`${encodeURIComponent(paramKey)}=${paramValue}`);
    }
  }

  return `?${ret.join('&')}`;
};

const getBffUrl = () => {
  const { web3dViewerBFFEndPoint: serverEndpoint } = appSettingsManager.getAppSettingsByValue(
    'environmentParametersSettings'
  );
  if (serverEndpoint) {
    return endsWith(serverEndpoint, '/') ? serverEndpoint.substr(0, serverEndpoint.length - 1) : serverEndpoint;
  }
};

const getScannerUrl = () => {
  const isStandalone = utils.isStandaloneMode();
  switch (true) {
    case isStandalone:
      return ScannerEnvironmentURLs.dev_host;
    case !isStandalone:
      return ScannerEnvironmentURLs.host;
    default:
      break;
  }
};

const getEnvironmentParams = (selector) => {
  const { apiMap } = map;
  const isEupEnv = utils.isEupHostEnv();
  const isAOHSEnv = utils.isAOHSEnv();
  const { path, method } = selector ? apiMap(selector) : {};
  const baseUrl = selector && (isEupEnv || isAOHSEnv) ? getBffUrl() : getScannerUrl();
  const defaultOptions = {
    credentials: 'include',
  };

  return {
    baseUrl,
    url: path,
    defaultOptions,
    method,
  };
};

export { getEnvironmentParams, compileUrl, getBffUrl, getScannerUrl, handleRequest, logToGAAndTimber, addHeaders };
