export default class TimberQueue {
  constructor() {
    this.unsentEngLogs = [];
  }

  addLogToTimberQueue(log) {
    this.unsentEngLogs.push(log);
  }

  sendUnsentLogs(sendFunc) {
    this.unsentEngLogs.forEach((log) => {
      sendFunc(log);
    });

    this.unsentEngLogs = [];
  }
}
