import { Vector3, Matrix4, Raycaster, MathUtils } from 'three';
import { compute_tx_diff, lin_mult } from '../utils/threeUtils';
import { commonConstants } from '../const_params';
import { cacheManager, cacheKeys } from '../../../cache-manager';
import Rails from '../classes/rails';
import { Candidate, CandidateGrid } from '../classes/candidate_grid';
import SurfaceProjectionSegmentationGrid from '../classes/surface_projection_segmentation_grid';
import { eventBus, globalEventsKeys } from '../../../event-bus';
import { projectPointToImage } from '../lumina.common.logic';
import logger from '../../../logger/logger';

const {
  rail_score_threshold,
  angle_compliance_coefficient,
  max_angle_difference_between_view_and_image_rails,
  max_rail_candidates,
  surface_projection_segment_size,
  preferable_angle_difference_between_view_and_image,
  rail_max_return_time,
  max_position_difference_between_sector_center_and_image_projection,
  min_image_capture_height,
  max_image_capture_height,
  preferable_image_capture_height,
  preferable_position_difference_between_sector_center_and_image_projection,
  min_distance_between_camera_and_segment_center_projection,
  max_distance_between_camera_and_segment_center_projection,
  position_compliance_coefficient,
  image_capture_height_compliance_coefficient,
  minimal_distance_approximation,
  camera_count,
  epsilon,
} = commonConstants;

const jaws = {
  upper_jaw: {},
  lower_jaw: {},
};

export const intersection_test = (lhs_img_metadata_tx, rhs_img_metadata_tx) => {
  const { trans_diff, angle_diff } = compute_tx_diff(lhs_img_metadata_tx, rhs_img_metadata_tx);
  return trans_diff < 10 && angle_diff < 10;
};

export const calculateOrthographicCameraProjection = (image_metadata, view_to_world_tx, mesh) => {
  const { img_cen_on_surf_pt } = image_metadata;

  const world_to_view_tx = new Matrix4().copy(view_to_world_tx).invert();
  const projectedPointOnModel = img_cen_on_surf_pt.clone();
  const point_in_projection_cs = projectedPointOnModel.applyMatrix4(world_to_view_tx);
  if (point_in_projection_cs.z > epsilon) return false;

  const { x, y } = point_in_projection_cs;
  const point_projected_to_screen = new Vector3(x, y, 0);
  const ray_starting_point = point_projected_to_screen.applyMatrix4(new Matrix4().copy(view_to_world_tx));

  const viewDirection = lin_mult(new Vector3(0, 0, -1), view_to_world_tx);

  // Calculate the vector from the camera to the object
  const vectorToObj = new Vector3().subVectors(img_cen_on_surf_pt, ray_starting_point);

  const rayCaster = new Raycaster(ray_starting_point, vectorToObj.normalize(), 0, Infinity);
  rayCaster.firstHitOnly = true;

  const intersects = rayCaster.intersectObjects([mesh]);

  const distanceFromIntersectToImageCenter = (() => {
    if (intersects && intersects.length > 0) {
      const intersect = intersects[0];
      const { point, face } = intersect;
      const distance = point.distanceTo(img_cen_on_surf_pt);
      const dot = face.normal.dot(viewDirection);
      const isFrontFace = dot < 0;
      return isFrontFace && distance < minimal_distance_approximation;
    }
    return false;
  })();

  return distanceFromIntersectToImageCenter;
};

export const calculateCenterSegmentProjection = (segmentCenter, mesh, view_to_world_tx) => {
  const rayStartingPoint = new Vector3(segmentCenter.x, segmentCenter.y, 0).applyMatrix4(view_to_world_tx);
  const viewDirection = lin_mult(new Vector3(0, 0, -1), view_to_world_tx);

  const rayCaster = new Raycaster(rayStartingPoint, viewDirection.normalize(), 0, Infinity);
  rayCaster.firstHitOnly = true;

  const intersects = rayCaster.intersectObjects([mesh]);

  if (intersects && intersects.length > 0) {
    const { point } = intersects[0];
    return point;
  }
  return null;
};

export const railsCandidateGrids = (rails, surface_projection_segmentation_grid, images_candidate_data) => {
  const rails_candidate_grids = new Array(rails.railsSize()).fill(null).map(
    () =>
      new CandidateGrid({
        rows: surface_projection_segmentation_grid.get_rows(),
        cols: surface_projection_segmentation_grid.get_cols(),
      })
  );

  for (let rail_index = 0; rail_index < rails.railsSize(); ++rail_index) {
    const rail_candidate_grid = rails_candidate_grids[rail_index];
    const current_rail = rails.get_rail(rail_index);
    for (let image_idx of current_rail) {
      for (let image_candidate_data of images_candidate_data[image_idx]) {
        // images with angle values below max threshold should always be preferred
        const {
          position_is_preferable,
          angle_is_preferable,
          capture_height_is_preferable,
          position_compliance,
          angle_compliance,
          capture_height_compliance,
          is_visible,
          angle_is_acceptable,
        } = image_candidate_data;

        const angle_compliance_score = angle_compliance_coefficient * angle_compliance;
        const capture_height_compliance_score = image_capture_height_compliance_coefficient * capture_height_compliance;
        const position_compliance_score = position_compliance_coefficient * position_compliance;

        const preferance_score =
          (position_compliance_coefficient +
            angle_compliance_coefficient +
            image_capture_height_compliance_coefficient) *
          position_is_preferable *
          angle_is_preferable *
          capture_height_is_preferable;

        const compliance_score = position_compliance_score + angle_compliance_score + capture_height_compliance_score;

        const score = preferance_score + compliance_score;

        if (is_visible && angle_is_acceptable && score > 0)
          rail_candidate_grid.set_cell_candidate({
            row: image_candidate_data.position.row,
            col: image_candidate_data.position.col,
            candidate: new Candidate(image_idx, score),
            enable_overwriting: true,
          });
      }
    }
  }

  return rails_candidate_grids;
};

export const imagesCandidateDataCalc = (
  surface_projection_segmentation_grid,
  images,
  view_to_world_tx,
  mesh,
  projectedSegmentCentersArr,
  currentActiveJaw
) => {
  // image score-related data precalculation
  const images_candidate_data = [];
  const max_angle_difference_between_view_and_image_rad = MathUtils.degToRad(
    max_angle_difference_between_view_and_image_rails
  );
  const preferable_angle_difference_between_view_and_image_rad = MathUtils.degToRad(
    preferable_angle_difference_between_view_and_image
  );

  for (let image_index = 0; image_index < images.length; ++image_index) {
    const image_metadata = images[image_index];
    const image_candidate_data = [];

    const {
      was_cam_projected,
      dist_from_cam_to_surf,
      img_cen_on_surf_pt,
      camera_to_pixel,
      K_vector,
      P_vector,
    } = image_metadata;

    if (
      was_cam_projected &&
      (dist_from_cam_to_surf >= min_image_capture_height && dist_from_cam_to_surf <= max_image_capture_height)
    ) {
      const is_visible = calculateOrthographicCameraProjection(image_metadata, view_to_world_tx, mesh);
      image_metadata.is_visible = is_visible;

      const angle_difference = image_metadata.camera_dir.angleTo(lin_mult(new Vector3(0, 0, -1), view_to_world_tx));
      const angle_is_acceptable = angle_difference <= max_angle_difference_between_view_and_image_rad;

      if (is_visible && angle_is_acceptable) {
        const angle_is_preferable = angle_difference <= preferable_angle_difference_between_view_and_image_rad;
        const angle_compliance = angle_is_preferable
          ? 1 - angle_difference / preferable_angle_difference_between_view_and_image_rad
          : 0;
        const capture_height_is_preferable = dist_from_cam_to_surf >= preferable_image_capture_height;
        const capture_height_compliance = capture_height_is_preferable ? 1 : 0;

        const segment_prox_data = surface_projection_segmentation_grid.get_positions_of_segments_from_point_neighborhood(
          img_cen_on_surf_pt,
          max_position_difference_between_sector_center_and_image_projection
        );

        for (let i = 0; i < segment_prox_data.length; i++) {
          const position_is_preferable =
            segment_prox_data[i].distance <= preferable_position_difference_between_sector_center_and_image_projection;
          const position_compliance = position_is_preferable
            ? 1 -
              segment_prox_data[i].distance / preferable_position_difference_between_sector_center_and_image_projection
            : 0;

          const { row, col } = segment_prox_data[i].position;
          const projectedSegmentCenter = projectedSegmentCentersArr[row][col];
          const { point } = projectedSegmentCenter;
          if (point) {
            const segmentCenterProjectedOnImage =
              projectedSegmentCenter.point &&
              projectPointToImage(
                projectedSegmentCenter,
                false,
                currentActiveJaw[image_index],
                camera_to_pixel,
                K_vector,
                P_vector
              );

            const isInROI =
              segmentCenterProjectedOnImage &&
              image_metadata.rect_of_image.includesPoint(segmentCenterProjectedOnImage);

            const distFromProjectedSegmentCenterToCamera = point.distanceTo(image_metadata.camera_pt);
            const distFromProjectedSegmentCenterToCameraIsAcceptable =
              distFromProjectedSegmentCenterToCamera >= min_distance_between_camera_and_segment_center_projection &&
              distFromProjectedSegmentCenterToCamera <= max_distance_between_camera_and_segment_center_projection;

            /*
              // this condition is temporary disabled untill algo team will decide otherwise.
              const distBetweenSgmCenterAndImgProjectionsIsAcceptable = 
              point.distanceTo(image_metadata.img_cen_on_surf_pt) < max_distance_between_image_and_segment_center_projections;
           */

            if (distFromProjectedSegmentCenterToCameraIsAcceptable && isInROI) {
              image_candidate_data.push({
                position: segment_prox_data[i].position,
                position_is_preferable: position_is_preferable,
                position_compliance: position_compliance,
                angle_is_acceptable: angle_is_acceptable,
                angle_is_preferable: angle_is_preferable,
                angle_compliance: angle_compliance,
                capture_height_is_preferable: capture_height_is_preferable,
                capture_height_compliance: capture_height_compliance,
                is_visible: is_visible,
              });
            }
          }
        }
      }
    }

    images_candidate_data.push(image_candidate_data);
  }

  return images_candidate_data;
};

export const initialize_rails = (images_meta_data_array) => {
  const num_of_frames = images_meta_data_array.length;
  const rails = new Rails();
  let first = true;

  for (let n = 0; n < camera_count; ++n) {
    let current_rail_idx = rails.create_rail();

    for (let img_idx = 0; img_idx < num_of_frames; ++img_idx) {
      if (img_idx === 0 && first) {
        first = false;
        rails.add_image_to_rail(img_idx, current_rail_idx);
        continue;
      }

      const image_meta_data = images_meta_data_array[img_idx];
      if (!image_meta_data) continue;

      const { camera_id, timestamp } = image_meta_data;
      if (camera_id !== n) continue;

      const img_timestamp = timestamp;
      const current_rail = rails.get_rail(current_rail_idx);
      let late_return_found = false;
      let close_scans_found = false;

      for (let i = 0; i < current_rail.length; ++i) {
        const cluster_img_idx = current_rail[i];

        const cluster_img = images_meta_data_array[cluster_img_idx];
        if (!cluster_img) continue;

        const cluster_img_timestamp = cluster_img.timestamp;

        if (intersection_test(image_meta_data.cam_to_abs_tx, cluster_img.cam_to_abs_tx)) {
          close_scans_found = true;
          if (img_timestamp - cluster_img_timestamp > rail_max_return_time) {
            late_return_found = true;
          }
        }
      }

      if (close_scans_found && !late_return_found) {
        rails.add_image_to_rail(img_idx, current_rail_idx);
      } else {
        current_rail_idx = rails.create_rail();
        rails.add_image_to_rail(img_idx, current_rail_idx);
      }
    }
  }

  return rails;
};

export const refresh_grid = (jaw_name, modelType, initializedMetadataObject) => {
  return new Promise((resolve) => {
    const { rails, images, mesh, currentActiveJaw } =
      jaw_name && initializedMetadataObject && (initializedMetadataObject[jaw_name] || {});

    const { camera } = cacheManager.get(cacheKeys.THREE_OBJECTS)[modelType][jaw_name];
    const grid = cacheManager.get(cacheKeys.CANDIDATE_GRID) || {};

    if (rails && images && mesh && camera && currentActiveJaw) {
      const view_to_world_tx = new Matrix4().multiplyMatrices(
        new Matrix4().copy(mesh.matrixWorld).invert(),
        camera.matrixWorld
      );
      const world_to_view_tx = new Matrix4().copy(view_to_world_tx).invert();
      const surface_projection_segmentation_grid = new SurfaceProjectionSegmentationGrid(
        mesh,
        world_to_view_tx,
        surface_projection_segment_size
      );

      const { rows, cols } = surface_projection_segmentation_grid;
      const projectedSegmentCentersArr = [];
      for (let i = 0; i < rows; i++) {
        const row = [];
        for (let j = 0; j < cols; j++) {
          const segmentCenter = surface_projection_segmentation_grid.get_segment_center(i, j);
          const segmentCenterProjectedPoint = calculateCenterSegmentProjection(segmentCenter, mesh, view_to_world_tx);
          row.push({ point: segmentCenterProjectedPoint });
        }
        projectedSegmentCentersArr.push(row);
      }

      // image score-related data precalculation
      const images_candidate_data = imagesCandidateDataCalc(
        surface_projection_segmentation_grid,
        images,
        view_to_world_tx,
        mesh,
        projectedSegmentCentersArr,
        currentActiveJaw
      );

      // rails candidate grids precalculation
      const rails_candidate_grids = railsCandidateGrids(
        rails,
        surface_projection_segmentation_grid,
        images_candidate_data
      );

      // final grid construction
      let candidate_grid = new CandidateGrid({
        rows: surface_projection_segmentation_grid.get_rows(),
        cols: surface_projection_segmentation_grid.get_cols(),
      });
      let not_added_rail_indices = Array.from(Array(rails.railsSize()).keys());
      let last_score = 0;

      for (let added_rails = 0; not_added_rail_indices.length > 0 && added_rails < max_rail_candidates; ++added_rails) {
        if (not_added_rail_indices.length > 0) {
          let best_rail_candidate_grid = null;
          let best_rail_idx = -1;
          const rails_to_remove = new Set();
          for (let rail_index of not_added_rail_indices) {
            const rail_candidate_grid = new CandidateGrid({
              grid: candidate_grid,
            });

            rail_candidate_grid.merge(rails_candidate_grids[rail_index], false);
            const current_score = rail_candidate_grid.get_score();
            if (current_score - last_score <= rail_score_threshold) {
              rails_to_remove.add(rail_index);
              continue;
            } else if (!best_rail_candidate_grid || current_score > best_rail_candidate_grid.get_score()) {
              best_rail_candidate_grid = rail_candidate_grid;
              best_rail_idx = rail_index;
            }
          }

          if (!best_rail_candidate_grid) {
            jaws[jaw_name] = { candidate_grid, surface_projection_segmentation_grid };
            grid[modelType] = { ...jaws };
            cacheManager.set(cacheKeys.CANDIDATE_GRID, grid);

            eventBus.raiseEvent(globalEventsKeys.REFRESH_GRID_CALCULATION_COMPLETTE, {
              candidate_grid,
              surface_projection_segmentation_grid,
              modelType,
            });
            return resolve(candidate_grid);
          }

          candidate_grid = best_rail_candidate_grid;
          rails_to_remove.add(best_rail_idx);
          not_added_rail_indices = not_added_rail_indices.filter((iRailId) => !rails_to_remove.has(iRailId));
          last_score = candidate_grid.get_score();
        }
      }

      jaws[jaw_name] = { candidate_grid, surface_projection_segmentation_grid };
      grid[modelType] = { ...jaws };
      cacheManager.set(cacheKeys.CANDIDATE_GRID, grid);

      eventBus.raiseEvent(globalEventsKeys.REFRESH_GRID_CALCULATION_COMPLETTE, {
        candidate_grid,
        surface_projection_segmentation_grid,
        modelType,
      });

      logger
        .info('Model Grid Calculated')
        .data('Model Grid Calculated')
        .to(['analytics', 'host'])
        .end();

      return resolve(candidate_grid);
    }
    logger
      .info('Model Grid Calculation error')
      .data('Model Grid Calculation error')
      .to(['analytics', 'host'])
      .end();

    return resolve(null);
  });
};

export const selectBestMatchImageByRails = (
  intersect,
  candidate_grid,
  surface_projection_segmentation_grid,
  currentActiveJaw,
  luminaImageMetaData
) => {
  let selected_pt = intersect.point;

  if (intersect && candidate_grid) {
    const position = surface_projection_segmentation_grid.get_segment_position(selected_pt);
    const candidate = position && candidate_grid.get_cell_candidate(position.row, position.col);

    if (candidate) {
      const img_id = candidate.candidate_id;
      const { camera_to_pixel, K_vector, P_vector } = luminaImageMetaData[img_id];
      const selected_point_on_image_px = projectPointToImage(
        intersect,
        false,
        currentActiveJaw[img_id],
        camera_to_pixel,
        K_vector,
        P_vector
      );

      return { img_idx: img_id, image: currentActiveJaw[img_id], selected2DPointOnImage: selected_point_on_image_px };
    }
  }
  return;
};
